import React, { useCallback, useState } from "react";
import "./App.css";

const getImages = async (query: string) => {
  const url = process.env.REACT_APP_WORKER_URL;
  if (!url) {
    return [];
  }
  const result = await fetch(url, {
    body: JSON.stringify({ query }),
    headers: {
      "Content-type": "application/json",
    },
    method: "POST",
  });
  return result.json();
};

interface Image {
  image: string;
  id: string;
  link: string;
}
const EmptyImages: Image[] = [];

function App() {
  const [query, setQuery] = useState("");
  const [images, setImages] = useState(EmptyImages);
  const search = useCallback(async () => {
    setImages(await getImages(query));
  }, [query]);
  return (
    <div className="App">
      <div className="form">
        <input
          className="border-gray-300 rounded-lg py-2 px-4  leading-normal m-10"
          placeholder="Search"
          type="text"
          onChange={(e) => setQuery(e.target.value)}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              search();
            }
          }}
        />
        <button onClick={() => search()}>Search</button>
      </div>
      <div className="">
        {images.map((i) => (
            <a
              className="inline-block"
              href={i.link}
              target="_blank noreferrer"
            >
              <img src={i.image} alt="sample" />
            </a>
        ))}
      </div>
    </div>
  );
}

export default App;
